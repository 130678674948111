* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  font-family: "Inter-Light", "Inter", sans-serif;
  text-decoration: none;
  background-color: var(--bg);
  color: var(--text) !important;
}
html {
  scroll-behavior: initial !important;
}
html,
body {
  min-height: 100%;
  height: auto;
}
:root {
  --bg: #000000;
  --text: #a2a2a2;
  /* second package */
  --border-radius: 30px;
  --padding: 10px 58px 10px 59px;
  --border-color: #323232;
  --dot-color: #656565;
  --widthLg: 90%;
  --widthMd: 90%;
  --mb: 2rem;
  --mt: 5rem;
  --transition: all ease-in-out 250ms;
  --lh: 1.7em;
}
::-webkit-scrollbar {
  height: 12px;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--border-color);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: var(--background);
}
.small_box {
  border-left: 2px solid var(--text);
  border-right: 2px solid var(--text);
  font-size: 14px;
  padding: 9px 20px;
  border-radius: var(--border-radius);
}
.small_box_content {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
}
.container {
  width: var(--widthLg);
  margin: 0 auto;
  padding-bottom: 1rem;
}
.small_box {
  border-left: 2px solid var(--border-color);
  border-right: 2px solid var(--border-color);
  font-size: 14px;
  padding: 9px 20px;
  border-radius: var(--border-radius);
}
.small_box_content {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-align: center;
  margin-top: 3rem;
}
@media screen and (max-width: 1024px) {
  .container {
    width: var(--widthMd);
    margin: 0 auto;
  }
  .small_box {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 700px) {
  .container {
    width: var(--widthLg);
    margin: 0 auto;
  }
  .mouseFollower {
    display: none !important;
  }
}
